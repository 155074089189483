import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useDealersLocations, useUpdate } from "hooks";
import { Appointment } from "models";
import { AppointmentsKeys } from "modules/Appointments/queries";
import { sortAppointments } from "modules/Appointments/util";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

interface AppointmentPinContext {
  appointments: Appointment[] | undefined;
}

interface MutationArgs {
  appointment_id: number;
  is_pinned: boolean;
  is_pinned_manually: boolean;
}

const useAppointmentPin = () => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const mutation = useMutation<MutationArgs, Error, MutationArgs, AppointmentPinContext>({
    mutationFn: async (params: MutationArgs) => {
      const response = await ApiInstance.post(`/appointments/update_pin`, params);
      return response.data;
    },
    onMutate: params => {
      const { appointment_id, is_pinned, is_pinned_manually } = params;
      const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list) || [];

      if (appointments?.length) {
        const { selectedLocation } = useDealersLocations();
        const updatedAppointments = appointments.map(a => (a.id === appointment_id ? ({ ...a, is_pinned, is_pinned_manually } as Appointment) : a));

        queryClient.setQueryData(AppointmentsKeys.list, sortAppointments(updatedAppointments, selectedLocation?.statuses));
      }

      const appointmentDetailsViewKey = [
        "realtime",
        {
          ...queryKeys.appointmentDetails.view,
          params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(params.appointment_id) }
        }
      ];

      const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      if (appointmentData) {
        const updatedAppointment = { ...appointmentData, is_pinned: params.is_pinned, is_pinned_manually: params.is_pinned_manually };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }

      return { appointments };
    },
    onError: (error, _variables, context) => {
      console.error(error);

      if (context?.appointments) queryClient.setQueryData(AppointmentsKeys.list, context.appointments);
    }
  });

  return mutation.mutate;
};

export default useAppointmentPin;
