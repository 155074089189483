import { useQuery } from "@tanstack/react-query";

import { WebSocketStatus } from "components/WebSocket";
import { WebSocketKeys } from "components/WebSocket/queries";

export const useWebSocketStatus = () => {
  const webSocketStatus = useQuery<WebSocketStatus>({ queryKey: WebSocketKeys.webSocketStatus() });

  return webSocketStatus.data?.isConnected;
};
