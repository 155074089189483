import { QueryFunctionContext, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

import { WebSocketMessageListener } from "components";
import { useDealersLocations, useRealTimeQuery, useUpdate } from "hooks";
import { Appointment, AppointmentNote, CustomerCommunication } from "models";
import { AppointmentsKeys } from "modules/Appointments/queries";
import ApiInstance from "util/Api";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";
import { dateFormat } from "util/common";
import { IBackendQueryKey } from "util/keyFactory";

export const useRealTimeAppointments = () => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();
  const { selectedLocation } = useDealersLocations();

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Appointment",
      filter: {
        dealer_location_id: Number(selectedLocation?.id)
      },
      permanent: true,
      callback: message => {
        const date = queryClient.getQueryData<Date>(AppointmentsKeys.selectedDate) || new Date();
        const appointment = updateAppointmentStatusIdentifier(message.data as Appointment);
        const appointments = [...(queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list) ?? [])];
        const idx = appointments.findIndex(a => a.id === appointment.id);
        if (idx >= 0) appointments[idx] = { ...appointments[idx], ...appointment } as Appointment;
        else if (moment(appointment.time_car_app).isSame(date, "day") || (appointment.is_pinned && moment(date).isSame(new Date(), "day"))) {
          appointments.push(appointment);
        } else return;

        queryClient.setQueryData(AppointmentsKeys.list, appointments);
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "create",
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const note = message.data as AppointmentNote;
        const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
        if (appIdx < 0 || appointments[appIdx].notes?.some(n => n.id === note.id)) return;

        queryClient.setQueryData(
          AppointmentsKeys.list,
          appointments.with(appIdx, { ...appointments[appIdx], notes: [...(appointments[appIdx].notes ?? []), note] } as Appointment)
        );
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "update",
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const note = message.data as AppointmentNote;
        const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
        if (appIdx < 0 || !appointments[appIdx].notes?.length) return;

        const noteIdx = appointments[appIdx].notes.findIndex(n => n.id === note.id);
        if (noteIdx < 0) return;

        queryClient.setQueryData(
          AppointmentsKeys.list,
          appointments.with(appIdx, {
            ...appointments[appIdx],
            notes: appointments[appIdx].notes.with(noteIdx, { ...appointments[appIdx].notes[noteIdx], ...note } as AppointmentNote)
          } as Appointment)
        );
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "delete",
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const note = message.data as AppointmentNote;
        const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
        if (appIdx < 0 || !appointments[appIdx].notes?.length) return;

        const noteIdx = appointments[appIdx].notes.findIndex(n => n.id === note.id);
        if (noteIdx < 0) return;

        queryClient.setQueryData(
          AppointmentsKeys.list,
          appointments.with(appIdx, {
            ...appointments[appIdx],
            notes: appointments[appIdx].notes.slice(0, noteIdx).concat(appointments[appIdx].notes.slice(noteIdx + 1))
          } as Appointment)
        );
        requestUpdate();
      }
    },
    {
      model: "CustomerCommunication",
      action: "create",
      filter: { dealer_location_id: Number(selectedLocation?.id) },
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const customer_communication = message.data as CustomerCommunication;
        const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
        if (appIdx < 0 || appointments[appIdx].customer_communication) return;

        queryClient.setQueryData(AppointmentsKeys.list, appointments.with(appIdx, { ...appointments[appIdx], customer_communication } as Appointment));
        requestUpdate();
      }
    },
    {
      model: "CustomerCommunication",
      action: "update",
      filter: { dealer_location_id: Number(selectedLocation?.id) },
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const customer_communication = message.data as CustomerCommunication;
        const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
        if (appIdx < 0 || !appointments[appIdx].customer_communication) return;

        queryClient.setQueryData(
          AppointmentsKeys.list,
          appointments.with(appIdx, {
            ...appointments[appIdx],
            customer_communication: { ...appointments[appIdx].customer_communication, ...customer_communication } as CustomerCommunication
          } as Appointment)
        );
        requestUpdate();
      }
    },
    {
      model: "CustomerCommunication",
      action: "upsert",
      filter: { dealer_location_id: Number(selectedLocation?.id) },
      permanent: true,
      callback: message => {
        const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
        if (!appointments?.length) return;

        const customer_communication = message.data as CustomerCommunication;
        const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
        if (appIdx < 0) return;

        queryClient.setQueryData(
          AppointmentsKeys.list,
          appointments.with(appIdx, {
            ...appointments[appIdx],
            customer_communication: { ...appointments[appIdx].customer_communication, ...customer_communication } as CustomerCommunication
          } as Appointment)
        );
        requestUpdate();
      }
    }
  ];

  const fetchAppointments = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>): Promise<Appointment[]> => {
    const { baseUrl, endpoint } = queryKey[0];
    const date = moment(queryClient.getQueryData<Date>(AppointmentsKeys.selectedDate) || new Date()).format(dateFormat);

    const response = await ApiInstance.post(endpoint, { location_id: selectedLocation?.id, date }, baseUrl);
    return response?.data?.appointments?.map((app: Appointment) => updateAppointmentStatusIdentifier(app)) || [];
  };

  return useRealTimeQuery({
    queryKey: AppointmentsKeys.list,
    queryFn: fetchAppointments,
    listeners,
    enabled: !!selectedLocation?.id
  });
};

export const useRefetchAppointments = () => ({ refetchAppointments: () => useQueryClient().invalidateQueries({ queryKey: AppointmentsKeys.list }) });
