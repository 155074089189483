import { useQueryClient } from "@tanstack/react-query";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import "components/NavBarSearch/NavBarSearch.scss";
import { NavBarSearchKeys } from "components/NavBarSearch/queries";
import { useSearchTerm } from "components/NavBarSearch/useSearchTerm";
import { ITranslation } from "util/interfaces";

type NavBarSearchProps = {
  children?: React.ReactNode;
};

export const NavBarSearch = ({ children }: NavBarSearchProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const queryClient = useQueryClient();
  const t = useTranslation().t as ITranslation;
  const cacheSearchTerm = useSearchTerm();

  useEffect(() => {
    if (!cacheSearchTerm && searchTerm) setSearchTerm("");
  }, [cacheSearchTerm]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    _debounce(() => queryClient.setQueryData(NavBarSearchKeys.searchTerm, e.target.value), 100, { leading: false, trailing: true })();
  };

  return (
    <div className="NavBarSearch">
      <Input
        icon="magnifying glass"
        iconPosition="left"
        placeholder={`${t("v8_search").message || "Search"}...`}
        className="nav-bar-search"
        onChange={handleSearchChange}
        value={searchTerm}
      />
      {children}
    </div>
  );
};
