import { WebSocketKeys } from "components/WebSocket/queries";
import ENV from "config/Env";
import { queryClient } from "index";
import { User } from "models";

type Filter = Record<string, number> | undefined;

export interface WebSocketStatus {
  isConnected: boolean;
}

type WebSocketLocationQueueAction = "create" | "update" | "append" | "delete" | "upsert";
type WebSocketAppointmentQueueAction = "ping";

export type WebSocketMessage = {
  model: string;
  action: WebSocketLocationQueueAction | WebSocketAppointmentQueueAction;
  id: number;
  data: unknown;
  user_id?: number;
};

export interface WebSocketMessageListener {
  model: string;
  action?: WebSocketLocationQueueAction | WebSocketAppointmentQueueAction;
  id?: number;
  filter?: Filter;
  callback: (message: WebSocketMessage) => void;
  permanent?: boolean;
}

interface InternalMessageListener extends WebSocketMessageListener {
  count: number;
}

enum QUEUE_ACTION {
  Subscribe = 1,
  Unsubscribe
}

export class WebSocketComponent {
  private static websocket: WebSocket | null = null;
  private static userId: number;
  private static exponentialBackoff = 500;

  private static MessageListeners: InternalMessageListener[] = [];

  private static matchFilter(filter: Filter, data: any) {
    if (!filter) return true;

    return Object.entries(filter).every(([key, value]) => data[key] === value);
  }

  private static handleWebSocketMessage(message: WebSocketMessage) {
    WebSocketComponent.MessageListeners.forEach(listener => {
      const { model, action, callback, id, filter } = listener;
      if (model === message.model && message.data && (!action || action === message.action) && (!id || id === message.id)) {
        if (WebSocketComponent.matchFilter(filter, message.data)) {
          try {
            callback(message);
          } catch (error) {
            console.warn("websocket message listener callback failed:", listener, error);
          }
        }
      }
    });
  }

  private static filterComparator(f1: Filter, f2: Filter) {
    if (!f1 && !f2) return true;
    if (!f1 || !f2) return false;

    const f1Keys = Object.keys(f1);

    if (f1Keys.length !== Object.keys(f2).length) return false;

    return f1Keys.every(key => f1[key] === f2[key]);
  }

  private static messageListenerComparator(l1: InternalMessageListener, l2: WebSocketMessageListener) {
    return (
      l1.model === l2.model && l1.action === l2.action && l1.id === l2.id && l1.permanent === l2.permanent && WebSocketComponent.filterComparator(l1.filter, l2.filter)
    );
  }

  static addMessageListener(listener: WebSocketMessageListener) {
    let internalListener = WebSocketComponent.MessageListeners.find(l => WebSocketComponent.messageListenerComparator(l, listener));

    if (internalListener) {
      internalListener.count += 1;
    } else {
      internalListener = { ...listener, count: 1 };
      WebSocketComponent.MessageListeners.push(internalListener);
    }

    return () => WebSocketComponent.removeMessageListener(internalListener);
  }

  private static removeMessageListener(listener: InternalMessageListener) {
    listener.count--;

    if (listener.count < 1 && !listener.permanent) {
      WebSocketComponent.MessageListeners = WebSocketComponent.MessageListeners.filter(l => l !== listener);
    }
  }

  static connect(userId: number) {
    WebSocketComponent.userId = userId;
    WebSocketComponent.websocket = new WebSocket(`${ENV.webSocketEndpoint}/connect?client_id=${userId}`);

    WebSocketComponent.websocket.onopen = () => {
      WebSocketComponent.exponentialBackoff = 500;
      queryClient.setQueryData<WebSocketStatus>(WebSocketKeys.webSocketStatus(), () => {
        return { isConnected: true };
      });
    };

    WebSocketComponent.websocket.onmessage = event => {
      try {
        WebSocketComponent.handleWebSocketMessage(JSON.parse(event.data));
      } catch (e) {
        console.warn(e);
      }
    };

    WebSocketComponent.websocket.onclose = () => {
      setTimeout(() => {
        if (WebSocketComponent.userId) {
          WebSocketComponent.exponentialBackoff *= 2;
          WebSocketComponent.connect(userId);
        }
      }, WebSocketComponent.exponentialBackoff);
    };
  }

  static subscribeToLocationQueue(notifierKey: string) {
    if (WebSocketComponent.websocket) {
      const subscribeMessage = {
        _type: QUEUE_ACTION.Subscribe,
        _queues: `v2-location-${notifierKey}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(subscribeMessage));
    }
  }

  static subscribeToAppointmentQueue(appointmentId: number) {
    if (WebSocketComponent.websocket) {
      const subscribeMessage = {
        _type: QUEUE_ACTION.Subscribe,
        _queues: `appointment-${appointmentId}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(subscribeMessage));
    }
  }

  static unsubscribeFromLocationQueue(notifierKey: string) {
    if (WebSocketComponent.websocket) {
      const unsubscribeMessage = {
        _type: QUEUE_ACTION.Unsubscribe,
        _queues: `v2-location-${notifierKey}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(unsubscribeMessage));
    }
  }

  static unsubscribeFromAppointmentQueue(appointmentId: number) {
    if (WebSocketComponent.websocket) {
      const unsubscribeMessage = {
        _type: QUEUE_ACTION.Unsubscribe,
        _queues: `appointment-${appointmentId}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(unsubscribeMessage));
    }
  }

  static sendAppointmentActiveUserPing(appointmentId: number, user: User) {
    if (WebSocketComponent.websocket) {
      const message = {
        id: appointmentId,
        _queues: `appointment-${appointmentId}`,
        model: "_ActiveUsersOnAppointmentDetailsPage",
        action: "ping",
        data: {
          first_name: user.first_name,
          last_name: user.last_name,
          id: user.id,
          profile_picture: user.profile_picture
        }
      };
      WebSocketComponent.websocket.send(JSON.stringify(message));
    }
  }

  static disconnect() {
    if (WebSocketComponent.websocket) {
      WebSocketComponent.userId = 0;
      WebSocketComponent.websocket.close();
      WebSocketComponent.MessageListeners = [];
      queryClient.setQueryData<WebSocketStatus>(WebSocketKeys.webSocketStatus(), () => {
        return { isConnected: false };
      });
    }
  }
}
