import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { WebSocketComponent, WebSocketMessageListener } from "components";
import { useWebSocketStatus } from "components/WebSocket/useWebSocketStatus";

interface ExtendedUseQueryOptions<TQueryFnData, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>
  extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  listeners: WebSocketMessageListener[];
}

export const useRealTimeQuery = <TQueryFnData, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: ExtendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const { listeners, ...restOptions } = options;
  const query = useQuery({ ...restOptions });
  const isConnected = useWebSocketStatus();
  const unsubscribeFunctions: (() => void)[] = [];

  useEffect(() => {
    if (listeners.length > 0 && query.isFetched) {
      listeners.forEach(listener => {
        const unsubscribe = WebSocketComponent.addMessageListener(listener);
        unsubscribeFunctions.push(() => unsubscribe());
      });
    }

    return () => {
      if (isConnected) {
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
      }
    };
  }, [listeners, query.isFetched]); // TODO this is trigered every time parents are rendering, as it's a new array ref every time... need a static ref

  return query;
};
