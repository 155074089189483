import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useUpdate, useUser } from "hooks";
import { Appointment, AppointmentNote, AppointmentNoteAttachment } from "models";
import { useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

type UseAppointmentNoteRequest = {
  path: string;
  payload: Record<string, string | number | undefined | null | Date | boolean | Partial<AppointmentNoteAttachment> | Partial<AppointmentNoteAttachment>[]>;
};

export const useAppointmentNotes = (appointment_id: number | undefined) => {
  const { requestUpdate } = useUpdate();
  const queryClient = useQueryClient();
  const user = useUser();

  const { appointmentData: appointment } = useUpdateAppointment(Number(appointment_id));

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointment_id) }
    }
  ];

  const updateAppointmentDetailsQueryCache = (update: Appointment) => {
    queryClient.setQueryData(appointmentDetailsViewKey, update);
    requestUpdate();
  };

  const addNote = async ({ path, payload }: UseAppointmentNoteRequest): Promise<number> => {
    const response = await ApiInstance.post(path, payload);

    return response.data;
  };

  const addAppointmentNote = useMutation({
    mutationFn: addNote,
    onSuccess: (id: number, variables) => {
      const { note, sa_remarks: saRemark, attachments, attachment, note_attachments: noteAttachments, ...rest } = variables.payload;
      const newNote = { id, note: note || saRemark, attachments: attachments || noteAttachments || (attachment && [attachment]), user, ...rest } as AppointmentNote;
      if (appointment) {
        appointment.notes = appointment.notes?.length ? [...appointment.notes, newNote] : [newNote];
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onMutate: newNote => newNote,
    onError: error => toast.error(error.message)
  });

  const updateNote = async ({ path, payload }: UseAppointmentNoteRequest): Promise<number> => {
    const response = await ApiInstance.post(path, payload);
    return response.data;
  };

  const updateAppointmentNote = useMutation({
    mutationFn: updateNote,
    onSuccess: (_, variables) => {
      if (appointment) {
        appointment.notes = appointment.notes?.map((appointmentNote: AppointmentNote) => {
          if (appointmentNote.id === variables.payload.appointment_note_id) {
            const { note, sa_remarks: saRemark, attachments, attachment, note_attachments: noteAttachments, ...rest } = variables.payload;
            const newNote = {
              ...appointmentNote,
              note: note || saRemark,
              attachments: attachments || noteAttachments || (attachment && [attachment]),
              ...rest
            } as AppointmentNote;
            return newNote;
          }
          return appointmentNote;
        });
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onMutate: updatedNote => updatedNote,
    onError: error => toast.error(error.message)
  });

  const deleteNote = async ({ noteID }: { noteID: number }) => {
    await ApiInstance.post("/appointment_notes/delete", { appointment_note_id: noteID });
  };

  const deleteAppointmentNote = useMutation({
    mutationFn: deleteNote,
    onSuccess: (_, variables) => {
      if (appointment) {
        appointment.notes = appointment.notes?.filter((note: AppointmentNote) => note.id !== variables.noteID);
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onError: error => toast.error(error.message)
  });

  return { addAppointmentNote, updateAppointmentNote, deleteAppointmentNote };
};
