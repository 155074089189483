import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useUpdate } from "hooks";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useKiosk = (appointment_id: number) => {
  const { requestUpdate } = useUpdate();
  const queryClient = useQueryClient();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointment_id) }
    }
  ];

  const detachKioskMutationFn = async () => {
    const res = await ApiInstance.post("/dashboard/labels/detach", { appointment_id }, ENV.kiosk);
    return res.data;
  };

  const detachKioskMutation = useMutation({
    mutationFn: detachKioskMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
      requestUpdate();
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return { detachKioskMutation };
};
