import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { WebSocketMessageListener } from "components";
import { useRealTimeQuery, useUpdate } from "hooks";
import {
  Appointment,
  AppointmentNote,
  AppointmentStatusHistory,
  Car,
  CheckInCommunicationResult,
  CommunicationAgreement,
  CommunicationEvent,
  CommunicationReceiver,
  CustomerCommunication,
  CustomerCommunicationIntervention,
  DiagnoseOverviewAgreedResult,
  DiagnoseOverviewContactResult,
  DiagnoseOverviewDeclinedResult,
  Intervention,
  KeylockerRemark,
  KioskRemark,
  PinModel
} from "models";
import { StatusData } from "modules/AppointmentDetails/components";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useAppointmentData = (id: string) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id }
    }
  ];

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Appointment",
      action: "update",
      id: Number(id),
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, ...(message.data as Appointment) });
        requestUpdate();
      }
    },
    {
      model: "Car",
      action: "update",
      callback: message => {
        const car = message.data as Car;
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (appointment?.car_id !== car.id) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, car: { ...appointment.car, ...car } });
        requestUpdate();
      }
    },
    {
      model: "Pin",
      action: "append",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.interventions?.length) return;

        const pin = message.data as PinModel;
        const interventionIdx = appointment.interventions.findIndex(i => i.id === pin.intervention_id);
        if (interventionIdx < 0 || appointment.interventions[interventionIdx].pin_history?.some(p => p.id === pin.id)) return;

        const intervention = appointment.interventions[interventionIdx];
        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          interventions: appointment.interventions.with(interventionIdx, { ...intervention, pin_history: [...(intervention.pin_history ?? []), pin] })
        });
        requestUpdate();
      }
    },
    {
      model: "Pin",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        const interventions = appointment?.interventions;
        if (!interventions?.length) return;

        const pin = message.data as PinModel;
        const interventionIdx = interventions.findIndex(i => i.id === pin.intervention_id);
        if (interventionIdx < 0) return;

        const intervention = interventions[interventionIdx];
        const history = intervention.pin_history;
        if (!history?.length) return;

        const pinIdx = history.findIndex(p => p.id === pin.id);
        if (pinIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          interventions: interventions.with(interventionIdx, { ...intervention, pin_history: history.slice(0, pinIdx).concat(history.slice(pinIdx + 1)) })
        });
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "create", // TODO should be append
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment) return;

        const note = message.data as AppointmentNote;
        if (appointment.notes?.some(n => n.id === note.id)) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, notes: [...(appointment.notes ?? []), note] });
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.notes?.length) return;

        const note = message.data as AppointmentNote;
        const noteIdx = appointment.notes.findIndex(n => n.id === note.id);
        if (noteIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, notes: appointment.notes.with(noteIdx, { ...appointment.notes[noteIdx], ...note }) });
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.notes?.length) return;

        const note = message.data as AppointmentNote;
        const noteIdx = appointment.notes.findIndex(n => n.id === note.id);
        if (noteIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, notes: appointment.notes.slice(0, noteIdx).concat(appointment.notes.slice(noteIdx + 1)) });
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "create",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        const intervention = message.data as Intervention;
        if (!appointment || appointment.interventions?.some(i => i.id === intervention.id)) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, interventions: [...(appointment.interventions ?? []), intervention] });
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.interventions?.length) return;

        const intervention = message.data as Intervention;
        const interventionIdx = appointment.interventions.findIndex(i => i.id === intervention.id);
        if (interventionIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          interventions: appointment.interventions.with(interventionIdx, { ...appointment.interventions[interventionIdx], ...intervention })
        });
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.interventions?.length) return;

        const intervention = message.data as Intervention;
        const interventionIdx = appointment.interventions.findIndex(i => i.id === intervention.id);
        if (interventionIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          interventions: appointment.interventions.slice(0, interventionIdx).concat(appointment.interventions.slice(interventionIdx + 1))
        });
        requestUpdate();
      }
    },
    {
      model: "KeyLockerRemark",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.keylocker_communications?.length) return;

        const remark = message.data as KeylockerRemark;
        const commIdx = appointment.keylocker_communications.findIndex(kc => kc.id === remark.keylocker_communication_id);
        if (commIdx < 0) return;

        const comm = appointment.keylocker_communications[commIdx];
        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          keylocker_communications: appointment.keylocker_communications.with(commIdx, { ...comm, remark: { ...comm.remark, ...remark } })
        });
        requestUpdate();
      }
    },
    {
      model: "KioskRemark",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.kiosk_communications?.length) return;

        const remark = message.data as KioskRemark;
        const commIdx = appointment.kiosk_communications.findIndex(kc => kc.id === remark.kiosk_communication_id);
        if (commIdx < 0) return;

        const comm = appointment.kiosk_communications[commIdx];
        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          kiosk_communications: appointment.kiosk_communications.with(commIdx, { ...comm, remark: { ...comm.remark, ...remark } })
        });
        requestUpdate();
      }
    },
    {
      model: "AppointmentStatusHistory",
      action: "append",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const history = message.data as AppointmentStatusHistory;
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment || appointment.status_history?.some(h => h.id === history.id)) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, status_history: [...(appointment.status_history ?? []), history] });
        requestUpdate();
      }
    },
    {
      model: "AppointmentStatusHistory",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.status_history?.length) return;

        const history = message.data as AppointmentStatusHistory;
        const historyIdx = appointment.status_history.findIndex(h => h.id === history.id);
        if (historyIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          status_history: appointment.status_history.with(historyIdx, { ...appointment.status_history[historyIdx], ...history })
        });
      }
    },
    {
      model: "CustomerCommunication",
      filter: { appointment_id: Number(id) },
      action: "create",
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment || appointment.customer_communication) return;

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, customer_communication: message.data });
        requestUpdate();
      }
    },
    {
      model: "CustomerCommunication",
      filter: { appointment_id: Number(id) },
      action: "update",
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment || !appointment.customer_communication) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...appointment.customer_communication, ...(message.data as CustomerCommunication) }
        });
        requestUpdate();
      }
    },
    {
      model: "CustomerCommunication",
      filter: { appointment_id: Number(id) },
      action: "upsert",
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...appointment.customer_communication, ...(message.data as CustomerCommunication) }
        });
        requestUpdate();
      }
    },
    {
      model: "CommunicationEvent",
      filter: { appointment_id: Number(id) },
      action: "append",
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...appointment.customer_communication, events: [...(appointment.customer_communication.events ?? []), message.data] }
        });
      }
    },
    {
      model: "CommunicationEvent",
      filter: { appointment_id: Number(id) },
      action: "upsert",
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication) return;

        const event = message.data as CommunicationEvent;
        const events = [...(appointment.customer_communication.events ?? [])];
        const idx = events.findIndex(e => e.id === event.id);
        if (idx >= 0) events[idx] = { ...events[idx], ...event };
        else events.push(event);

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, customer_communication: { ...appointment.customer_communication, events } });
      }
    },
    // {
    //   model: "CheckInResult",
    //   action: "append",
    //   filter: { appointment_id: Number(id) },
    //   callback: message => {
    //     const result = message.data as CheckInCommunicationResult;
    //     const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
    //     const comm = appointment?.customer_communication;
    //     if (!comm || comm.check_in_results?.some(r => r.id === result.id)) return;

    //     queryClient.setQueryData(appointmentDetailsViewKey, {
    //       ...appointment,
    //       customer_communication: { ...comm, check_in_results: [...(comm.check_in_results ?? []), result] }
    //     });
    //   }
    // },
    {
      model: "CheckInResult",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        const comm = appointment?.customer_communication;
        if (!comm?.check_in_results?.length) return;

        const result = message.data as CheckInCommunicationResult;
        const resultIdx = comm.check_in_results.findIndex(r => r.id === result.id);
        if (resultIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...comm, check_in_results: comm.check_in_results.with(resultIdx, { ...comm.check_in_results[resultIdx], ...result }) }
        });
      }
    },
    {
      model: "CheckInRemark",
      action: "append",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        const comm = appointment?.customer_communication;
        if (!comm) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...comm, check_in_remarks: [...(comm.check_in_remarks ?? []), message.data] }
        });
      }
    },
    {
      model: "CommunicationReceiver",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const receiver = message.data as CommunicationReceiver;
        const eventIdx = events.findIndex(e => e.id === receiver.communication_event_id);
        if (eventIdx < 0) return;

        const receivers = events[eventIdx].receivers;
        if (!receivers?.length) return;

        const receiverIdx = receivers.findIndex(r => r.id === receiver.id);
        if (receiverIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], receivers: receivers.with(receiverIdx, { ...receivers[receiverIdx], ...receiver }) })
          }
        });
      }
    },
    {
      model: "CustomerCommunicationIntervention",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const result = message.data as CustomerCommunicationIntervention;
        const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
        if (eventIdx < 0) return;

        const results = events[eventIdx].intervention_results;
        if (!results?.length) return;

        const resultIdx = results.findIndex(r => r.id === result.id);
        if (resultIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], intervention_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
          }
        });
      }
    },
    {
      model: "CommunicationAgreement",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const agreement = message.data as CommunicationAgreement;
        const eventIdx = events.findIndex(e => e.id === agreement.communication_event_id);
        if (eventIdx < 0) return;

        const agreements = events[eventIdx].agreements;
        if (!agreements?.length) return;

        const agreementIdx = agreements.findIndex(r => r.id === agreement.id);
        if (agreementIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], agreements: agreements.with(agreementIdx, { ...agreements[agreementIdx], ...agreement }) })
          }
        });
      }
    },
    {
      model: "DiagnoseOverviewRemark",
      action: "append",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        const comm = appointment?.customer_communication;
        if (!comm) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: { ...comm, diagnose_overview_remarks: [...(comm.diagnose_overview_remarks ?? []), message.data] }
        });
      }
    },
    {
      model: "DiagnoseOverviewAgreedResult",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const result = message.data as DiagnoseOverviewAgreedResult;
        const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
        if (eventIdx < 0) return;

        const results = events[eventIdx].diagnose_overview_agreed_results;
        if (!results?.length) return;

        const resultIdx = results.findIndex(r => r.id === result.id);
        if (resultIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_agreed_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
          }
        });
      }
    },
    {
      model: "DiagnoseOverviewDeclinedResult",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const result = message.data as DiagnoseOverviewDeclinedResult;
        const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
        if (eventIdx < 0) return;

        const results = events[eventIdx].diagnose_overview_declined_results;
        if (!results?.length) return;

        const resultIdx = results.findIndex(r => r.id === result.id);
        if (resultIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_declined_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
          }
        });
      }
    },
    {
      model: "DiagnoseOverviewContactResult",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
        if (!appointment?.customer_communication?.events?.length) return;

        const events = appointment?.customer_communication?.events;
        const result = message.data as DiagnoseOverviewContactResult;
        const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
        if (eventIdx < 0) return;

        const results = events[eventIdx].diagnose_overview_contact_results;
        if (!results?.length) return;

        const resultIdx = results.findIndex(r => r.id === result.id);
        if (resultIdx < 0) return;

        queryClient.setQueryData(appointmentDetailsViewKey, {
          ...appointment,
          customer_communication: {
            ...appointment.customer_communication,
            events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_contact_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
          }
        });
      }
    }
  ];

  const getAppointment = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint, params } = queryKey[1] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { ...params, id: Number(params?.id) });
    const appointment: Appointment = res.data;
    appointment.status_history?.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
    return appointment;
  };

  const query = useRealTimeQuery({
    queryKey: appointmentDetailsViewKey,
    queryFn: getAppointment,
    listeners
  });

  const updateStatus = async (data: StatusData) => {
    const updateData = {
      id: Number(id),
      ...data
    };

    const res = await ApiInstance.post("/appointments/update", updateData);
    return res.data;
  };

  const statusMutation = useMutation({
    mutationFn: updateStatus,
    onError: (e, _variables, _context) => {
      toast.error(e.message);
    }
  });

  const getAppointmentCancelReasons = async () => {
    const res = await ApiInstance.get("/appointments/cancel_reasons");
    return res.data;
  };

  const cancelReasonsQuery = useQuery({
    queryKey: ["realtime", queryKeys.appointmentDetails.cancelReasons],
    queryFn: getAppointmentCancelReasons,
    retry: false
  });

  const cancelAppointment = async (reason: any) => {
    const updateData = {
      appointment_id: Number(id),
      ...reason
    };
    const res = await ApiInstance.post("/appointments/cancel", updateData);
    return res.data;
  };

  const cancelAppointmentMutation = useMutation({
    mutationFn: cancelAppointment,
    onError: (e, _variables) => {
      toast.error(e.message);
      const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      queryClient.setQueryData(appointmentDetailsViewKey, appointmentData);
    }
  });

  const restoreAppointment = async () => {
    const res = await ApiInstance.post("/appointments/restore", { appointment_id: Number(id) });
    return res.data;
  };

  const restoreAppointmentMutation = useMutation({
    mutationFn: restoreAppointment
  });

  return {
    data: query.data,
    loading: query.isFetching,
    error: query.error,
    updateStatus: statusMutation,
    cancelReasonsQuery,
    cancelAppointment: cancelAppointmentMutation,
    restore: restoreAppointmentMutation
  };
};
