import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations, useUpdate } from "hooks";
import { Appointment, DMS } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useUpdateAppointment = (appointmentId: number) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();
  const { selectedLocation } = useDealersLocations();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointmentId) }
    }
  ];

  const { data: appointmentData }: { data: Appointment | undefined } = useQuery({ queryKey: appointmentDetailsViewKey });

  const updateAppointment = (data: Partial<Appointment>) => {
    const AppointmentSnapshot = queryClient.getQueryData(appointmentDetailsViewKey);
    if (AppointmentSnapshot) {
      const updatedData = { ...(AppointmentSnapshot as Appointment), ...data };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedData);
      requestUpdate();
    }
    return { AppointmentSnapshot };
  };
  const handleError = (e: any, _variables: Partial<Appointment>, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(appointmentDetailsViewKey, context.AppointmentSnapshot);
      requestUpdate();
    }
  };

  const appointmentUpdateDatesMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/update_dates", { appointment_id: appointmentId, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentUpdateMileagesMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/update_mileages", { appointment_id: appointmentId, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentCarInShopMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/set_car_in_shop", { appointment_id: appointmentId, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentCarOutOfShopMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/set_car_out_of_shop", { appointment_id: appointmentId, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const handleDMSRefresh = (data: Appointment) => {
    if (selectedLocation?.dms_id === DMS.KeyLoopJobs || selectedLocation?.dms_id === DMS.KeyLoopMenus) {
      return ApiInstance.post("/appointments/refresh", { appointment_id: data.id }, ENV.keyloopBaseURL);
    }
    if (selectedLocation?.dms_id === DMS.AutoFlex) {
      return ApiInstance.post("/appointments/refresh", { appointment_id: data.id }, ENV.autoflexBaseUrl);
    }
    throw new Error("Invalid DMS id");
  };

  const dmsRefreshMutation = useMutation({
    mutationFn: handleDMSRefresh,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return {
    dmsRefreshMutation,
    appointmentData,
    appointmentUpdateDatesMutation,
    appointmentUpdateMileagesMutation,
    appointmentCarInShopMutation,
    appointmentCarOutOfShopMutation
  };
};
